import axios from "axios";
import Code from '@/api/utils/code'
import {Dialog, Toast} from 'vant';
import _this  from '../main'
import store from '@/store/index.js'


const main = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000000000
});
main.interceptors.request.use(config => {
    let uuid = localStorage.getItem("uuid");
    let sign = localStorage.getItem("sign");
    config.headers['uuid'] = uuid;
    config.headers['sign'] = sign;
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
});

/**
 * @author 龙傲天
 * @desc   response登出。
 */
main.interceptors.response.use(
    res => {
        if(res.data.code==0){
            return res;
        }else{
            if(res.data.code == Code.UNAUTHEN || res.data.code == Code.UNLOGIN){
                Dialog.alert({
                    title: '登陆提醒',
                    message: '登陆超时或未登陆，重新登录！'
                }).then(() => {
                    if(store.state.groupRouter.indexOf(location.pathname) != -1){
                        console.log(location.pathname)
                        _this.$router.push({name:'GroupLogin'});
                        return;
                    }
                    _this.$router.push({name:'Login'});
                })
            }else{
                Toast.fail(res.data.msg);
                return res;
            }
            return res;
        }
    }
)

export {main};