import {main} from "../main";
export default {
    loginByGroup(params){
        return  main.post('/mobile/client/group/login',params)
    },
    groupInfo(params){
        return  main.get('/mobile/client/group/info',{params:params})
    },
    groupCollectCreate(params){
        return  main.get('/mobile/client/group/collect/create',{params:params})
    },
    groupGetAppid(params){
        return  main.get('/mobile/client/group/get/appid/by/groupUuid',{params:params})
    },
    //微信支付->集团客户微信公众号收款
    groupPayOrder(params){
        return  main.post('/pay/weixin/group/order',params)
    },

    //集团客户微信公众号收款详情
    groupPayOrderInfo(params){
        return  main.get('/mobile/client/group/order/info',{params:params})
    },

    //集团客户-> 获取appid，通过msisdn
    groupGetAppidByMsisdn(params){
        return  main.get('/mobile/client/group/get/appid/by/msisdn',{params:params})
    },

    

    

}