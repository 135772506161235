<template>
  <div id="app">
    <van-nav-bar
      :title="$store.state.pageTitle">
    </van-nav-bar>

    <!-- :left-arrow="showBackBtn"  @click-left="goBack" -->
    <transition name="fade" mode="out-in">
      <router-view class="router"></router-view>
    </transition>
  </div>

</template>
<script>
import Url from '@/common/Url.js';
import wxSdk from "@/common/WxSdk.js";
export default {
    name: 'app',
    data() {
        return {
            showBackBtn: false,
            historyUrl: sessionStorage.historyUrl ? JSON.parse(sessionStorage.historyUrl) : [],
            backFlag: false, // 记录本次路由更新是否为用户的后退操作
            way: 3,
            appid:'wxe795f972eb691695',
            groupRouter:this.$store.state.groupRouter,
            flag:0
        }
    },
    methods: {
        //回退
        changeGroupRouter(){
          let groupClientUUID = localStorage.getItem("groupClientUUID");
          if(location.pathname == '/group/pay/finish')return;
          if(!!groupClientUUID && location.pathname == '/group/pay'){
            this.$router.push({name:'GroupPay'})
          }else if(!groupClientUUID){
            this.$router.push({name:'GroupLogin'})
          }
          else{
            this.$router.push({name:'GroupHome'})
          }
        },
        goBack() {
            this.backFlag = true; // 设置本次页面跳转为后退跳转，让本次跳转不写进historyUrl记录中
            this.$router.push(this.historyUrl[0]);
            this.historyUrl.splice(0, 1);
        },
        async wxInit() {
            //根据openId，执行WxSDK的初始化
            let openId = this.$store.state.openId;
            if (openId) {
                console.log('exists openId,init sdk running');
                this.initWxSdk(); //有openID 可以执行wxSDK
                return;
            }
            let code = Url.getQuery("code");
            if (code === null) {
                console.log("not exist openId and code, redirect to  weixin auth !");
                location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${encodeURIComponent(
                    `${location.href}`
                )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
            } else {
                console.log("not exist openId but url has code params,redirect to get openId");
                let groupUuids = ''
                if(this.groupRouter.indexOf(location.pathname) != -1 || localStorage.groupClientFlag == '1'){
                    groupUuids = localStorage.getItem("groupUUID") ||  Url.getQuery("groupUuid") 
                }
                let params = {code:code,groupUuid:groupUuids}                
                this.$api.getWeixinOpenId(params).then(res => {
                    if (res.data.code == 0) {
                        (this.$store.state.openId = localStorage.openId = res.data.data);
                        this.initWxSdk();
                    }
                });
            }
        },
        initWxSdk() {
            wxSdk(
                "物联网卡智能服务平台！",
                "简单、快速、准确查询物联网卡信息！",
                `http://www.baidu.com`,
                require("@/assets/img/logo.png")
            ).then(d => {
                console.log("WxSDK init successful，can share with logo and title!");
                this.$store.state.wxSDKReady = true;
                if(!!Url.getQuery("msisdn") && !!Url.getQuery("sign") && !!Url.getQuery("uuid")){
                    this.$api.loginDefault({sign:Url.getQuery("sign"), uuid:Url.getQuery("uuid"),msisdn:Url.getQuery("msisdn")}).then(res => {
                      if(res.data.code == 0){
                        localStorage.setItem("sign",res.data.data.sign);
                        localStorage.setItem("uuid",res.data.data.uuid);
                        localStorage.setItem("msisdn",res.data.data.msisdn);
                        this.$router.push('/card/info');
                      }
                    })
                } else if(!!Url.getQuery("smsSend")){
                    let uuid = localStorage.getItem("uuid");
                    if(!uuid){
                      this.$router.push({name:'Login'})
                    }else{
                      this.$router.push({name:'smsSend'})
                    }
                }else if(!!Url.getQuery("bindId") && location.pathname == '/bind/admin'){
                  this.$store.commit('setBindId',Url.getQuery("bindId"))
                  this.$router.push({path: '/bind/admin', query: {bindId: Url.getQuery("bindId")}});
                }
                else if(this.groupRouter.indexOf(location.pathname) != -1){
                  this.changeGroupRouter();
                } else{
                  // let uuid = localStorage.getItem("uuid");
                  // let sign = localStorage.getItem("sign");
                  if(!!localStorage.getItem("uuid") && !!localStorage.getItem("sign")){
                    this.$router.push({name:'Home'});
                  }else{
                    this.$router.push({name:'Login'});
                  }
                }
            });
        },

        judgeGroupUuid(){
          if(!!Url.getQuery("groupUuid")){
            if(Url.getQuery("groupUuid") != localStorage.groupUUID){
              localStorage.setItem("groupClientUUID",'');
              localStorage.setItem("appId",'');
            }
            localStorage.setItem("groupUUID",Url.getQuery("groupUuid"));
          }
        },
        judgeGroupClientUuid(){
          if(!!Url.getQuery("clientUuid") && location.pathname == '/group/pay'){
            localStorage.setItem("groupClientUUID",Url.getQuery("clientUuid"));
          }
        },
        getGroupAppId(){
          if(!!localStorage.getItem("appId")){
            this.appid = localStorage.getItem("appId")
            this.wxInit();
          }else{
            this.$api.groupGetAppid({groupUuid:localStorage.getItem("groupUUID")}).then(res => {
              if(res.data.code == 0){
                localStorage.setItem("appId",res.data.data);
                this.appid = res.data.data
                this.wxInit();
              }
            })
          }
        },
        getAppId(){
          localStorage.setItem("groupUUID",'');
          let msisdn = Url.getQuery("msisdn") || localStorage.msisdn
          this.$api.groupGetAppidByMsisdn({msisdn:msisdn}).then(res => {
            if(res.data.code == 0){
              this.appid = res.data.data.weixinOfficialAppId
              localStorage.setItem("groupClientFlag",!!res.data.data.groupUuid ? '1' : '2');
              localStorage.setItem("groupUUID",res.data.data.groupUuid);
              localStorage.setItem("appId",res.data.data.weixinOfficialAppId);
              this.wxInit();
            }
          })
        }
  },
  computed: {

  },
  watch: {
    '$route'(to, from) {
       if(to.name == "Login" && from.name =="smsSend"){
         localStorage.setItem("smsSend","1");
       }else{
         localStorage.setItem("smsSend","2");
       }
      if(!this.backFlag) { 
        if(from.path != this.historyUrl[0]) { 
          if(this.historyUrl.length > 0 && from.path == "/") {
          } else {
            this.historyUrl = [from.path, ...this.historyUrl]; 
          }
        }
      } else {
        this.backFlag = false; 
      }
      sessionStorage.historyUrl = JSON.stringify(this.historyUrl);
      if(this.$route.path == "/"){
        this.showBackBtn = false;
      } else {
        this.showBackBtn = true;
      }
    }
  },
  created() {
    // 判断 GroupUuid 是否存在并赋值
    this.judgeGroupUuid()

    // 判断 是否集团子页面 && GroupClientUuid 是否存在并赋值
    this.judgeGroupClientUuid()

    // 拿取正确appid  判断是否是集团页面
    if(this.groupRouter.indexOf(location.pathname) != -1){
      this.flag = 1
    }else if(Url.getQuery("msisdn") || localStorage.msisdn){
      this.flag = 2
    }

    switch (this.flag) {
      case 1:
        this.getGroupAppId()
        break;
      case 2:
        this.getAppId()
        break;
      default:
        localStorage.setItem("groupUUID",'');
        this.wxInit();
        break;
    }
    console.log();
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 5px;
  /* REM适配，但为了PC端显示正常，页面最高不大于750px */
  max-width: 750px;
  margin: 0 auto;
}
/* 路由切换过渡动画 */
.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: opacity .35s;
}
.fade-leave{
    opacity: 0;
}
.fade-leave-active{
    transition: opacity .35s;
}

/* 以下是页面样式 */
.van-nav-bar__title, .van-nav-bar__arrow{
  font-size: 36px;
  color: #222 !important;
}
.van-nav-bar{
  height: 100px;
  line-height: 100px;
  width: 750px;
  position: fixed;
  top: 0;
  /* background-color: #f4f4f4; */
}
.router{
  padding-top:80px;
}
</style>
