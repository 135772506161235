import {main} from "../main";
export default {
    loginByAccount(params){
        return  main.post('/client/mobile/login',params)
    },
    loginDefault(params){
        return  main.get('/client/mobile/in/login',{params:params})
    },
    weixinLogin(params){
        return  main.get('/client/mobile/weixin/weixinLogin',{params:params})
    },
    createAccChargeOrder(params){
        return  main.post('/client/account/createOrder', params)
    },
    queryHasPayed(params){
        return  main.get('/client/account/charge/status', {params:params})
    },
    setMobileOpenId(params){
        return  main.post('/client/mobile/set/openId', params)
    },
    
}