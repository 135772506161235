const upPack = () => import ('@/views/up/Pack')
const upDetail = () => import ('@/views/up/Detail')
export default [
    {
        name:'upPack',
        path: '/up/pack',
        component: upPack
    },{
        name:'upDetail',
        path: '/up/detail',
        component: upDetail
    },
    
]