import {main} from "../main";
export default {
    //移动端API接口 -> 客户短信相关信息
    getClientSmsInfo(params){
        return  main.get('/client/mobile/sms/client/info',{params:params})
    },
    //移动端API接口 -> 短信订单
    addSmsOrder(params){
        return  main.put('/client/mobile/sms/add/order',params)
    },
    //移动端API接口 -> 短信发送
    smsSendSave(params){
        return  main.put('/client/mobile/sms/send/save',params)
    },
    getSmsList(params){
        return main.get("/client/mobile/sms/listPage", {params});
    }
}