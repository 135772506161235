const Home = () => import ('@/views/home/Index')
const CardInfo = () => import ('@/views/home/CardInfo')
const Recharge = () => import ('@/views/home/Recharge')
const Cycle = () => import('@/views/renew/Index')
const Login = () => import('@/views/home/Login')
const Loading = () => import('@/views/home/Loading')
const Binding = () => import('@/views/bind/Index')
const BindingSuccess = () => import('@/views/bind/Success')

export default [
    {  path: '/',  name:'Loading',       component: Loading},
    {  path: '/home',  name:'Home',       component: Home},
    {  path: '/card/info',component: CardInfo},
    {  path: '/recharge', component: Recharge},
    {  path: '/cycle',    component: Cycle},
    {  path: '/login',  name:'Login',  component: Login},
    {  path: '/bind/admin',  name:'Binding',  component: Binding},
    {  path: '/bind/success',  name:'BindingSuccess',  component: BindingSuccess}
]
