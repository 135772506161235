import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import group from './modules/group'
import home from './modules/home'
import pay from './modules/pay'
import refueing from './modules/refueing'
import up from './modules/up'
import sms from './modules/sms'
import notice from './modules/notice'
const router = new Router({
    mode: 'history',
    routes: [
        ...group,
        ...home,
        ...pay,
        ...refueing,
        ...up,
        ...sms,
        ...notice
    ]
})
export default router;
