const GroupHome = () => import ('@/views/group/Index')
const GroupLogin = () => import('@/views/group/Login')
const GroupPay = () => import('@/views/group/Pay')
const GroupOrder = () => import('@/views/pay/GroupOrder')

export default [
    {  path: '/group/home',  name:'GroupHome',       component: GroupHome},
    {  path: '/group/login',  name:'GroupLogin',  component: GroupLogin},
    {  path: '/group/pay',  name:'GroupPay',  component: GroupPay},
    {  path: '/group/pay/finish',  name:'GroupOrder',  component: GroupOrder}
]