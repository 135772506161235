import Vue from 'vue'
import Vuex from 'vuex'
import dictionaries from './modules/dictionaries'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        pageTitle: '',
        groupRouter:['/group/login', '/group/home', '/group/pay', '/group/pay/finish'],
        bindId:0
    },
    mutations: {
        setPageTitle: (state, data) => {
            state.pageTitle = data;
        },
        setBindId: (state, data) => {
            state.bindId = data;
        },
    },
    actions: {

    },
    modules: {
        dictionaries
    },
})

export default store
