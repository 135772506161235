import {main} from "../main";
export default {
    //物联网卡-> 加油包查询
    getRefueingInfo(params){
        return  main.get('/client/mobile/refueing/get/package',{params:params})
    },
    //物联网卡 -> 加油包订单
    addRefueing(params){
        return  main.put('/client/mobile/refueing/add', params)
    },
    //移动端API接口 -> 是否能生成充值加油包订单
    refueingOrderEstablish(params){
        return  main.post('/client/mobile/refueing/order/establish',params)
    },
    getRefueingDiscount(params){
        return  main.get('/client/mobile/card/get/package/discount',{params:params})
    },
    //获取启用的加油包配置
    getPackageConfigUse(params){
        return  main.get('/client/mobile/card/get/package/config/use',{params:params})
    },



    
}