const Renew = () => import ('@/views/pay/Renew')
const payRefueing = () => import ('@/views/pay/Refueing')
const chargeUp = () => import ('@/views/pay/Up')
const smsPay = () => import ('@/views/pay/Sms')

export default [
    {  name:'Renew',  path: '/renew',  component: Renew },
    {  name:'payRefueing', path: '/pay/refueing',  component: payRefueing},
    {  name:'chargeUp',path: '/pay/up',  component: chargeUp},
    {  name:'smsPay',path: '/pay/sms',  component: smsPay},

]